import { useAPI, useCacheWrapper } from '#imports'

export function useCatalogStore () {
  const api = useAPI()

  async function getInterestingSection (path: string) {
    return useCacheWrapper(['interesting-section', path], async () => {
      const { getInterestingSection } = api.catalog()

      const data = await getInterestingSection(path)

      return data || []
    })
  }

  async function getPopularSections (category: string) {
    return await useCacheWrapper(['popular-sections', category], () => {
      const { getPopularSections } = api.catalog()

      const data = getPopularSections(category)

      return data || []
    })
  }

  return {
    getInterestingSection,
    getPopularSections
  }
}
